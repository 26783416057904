<template>
  <div :draggable="draggable" class="video-box" @dragstart="dragstart(data)" @dragenter="dragenter(data,$event)" @dragend="dragend(data,$event)" @dragover="dragover($event)">
    <div :id="`video-full-${index}`" class="video-c">
      <div v-if="data.siteID" class="no-video-playing">
        暂无视频
      </div>
      <!-- v-if 添加是为了触发排序后的视图重新渲染 -->
      <video v-if="data.first || data.second || data.internal" :id="`video-${data.siteID}`" />
      <div v-if="!data.siteID" class="no-video">
        <span class="dot" />
        无视频
      </div>
      <div v-else class="video-info">
        <span class="dot" />
        {{ data.siteName }}
      </div>
      <div class="control-c">
        <div v-show="data.first || data.second || data.internal" class="control-left">
          <el-select v-model="data.viewValue" placeholder="请选择" @change="toggleVideoView(data)">
            <el-option v-for="x in options" :key="x.value" :label="x.label" :value="x.value" />
          </el-select>
        </div>
        <div class="full-screen-btn" @click="videoFullScreen(`video-full-${index}`)">
          <i class="el-icon-full-screen" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line
  name: 'VideoItem',
  components: {
  },
  props: {
    index: {
      type: Number,
      default: 0
    },
    data: {
      type: Object,
      default: () => { }
    },
    draggable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      options: [{
        value: 'first',
        label: '第一视角'
      }, {
        value: 'second',
        label: '第三视角'
      }, {
        value: 'internal',
        label: '内窥视角'
      },
      {
        value: 'internal',
        label: '机械臂视角'
      }]
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    toggleVideoView(video) {
      this.$emit('emitAction', {
        action: 'toggleVideoView',
        data: video
      });
    },
    videoFullScreen(videoId) {
      this.$emit('emitAction', {
        action: 'videoFullScreen',
        data: {
          videoId
        }
      });
    },
    dragstart(value) {
      if (!this.draggable) return;
      this.$emit('emitAction', {
        action: 'dragstart',
        data: {
          value
        }
      });
    },
    // 记录移动过程中信息
    dragenter(value, e) {
      if (!this.draggable) return;
      this.$emit('emitAction', {
        action: 'dragenter',
        data: {
          value,
          e
        }
      });
    },
    // 拖拽最终操作
    dragend() {
      if (!this.draggable) return;
      this.$emit('emitAction', {
        action: 'dragend'
      });
    },
    // 拖动事件（主要是为了拖动时鼠标光标不变为禁止）
    dragover(e) {
      if (!this.draggable) return;
      this.$emit('emitAction', {
        action: 'dragover',
        data: {
          e
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.video-c {
  width: 100%;
  height: 100%;
  background-color: #0f1f39;
  position: relative;
  &:hover {
    .full-screen-btn {
      display: block;
    }
  }
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .no-video-playing {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #fff;
  }
  .control-c {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .control-left {
      width: 120px;
      /deep/ .el-select .el-input__inner {
        border-radius: 15px;
        height: 30px;
        background: rgba(0, 0, 0, 0.15);
      }
      /deep/ .el-input__icon {
        line-height: 30px;
      }
    }
  }
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 7px;
  }
  .no-video {
    width: 72px;
    height: 28px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    font-size: 14px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 16px;
    left: 16px;
    .dot {
      background: #ffffff;
    }
  }
  .video-info {
    position: absolute;
    top: 16px;
    left: 16px;
    font-size: 14px;
    color: #fff;
    text-shadow: #000 2px 0 0, #000 0 2px 0, #000 -2px 0 0, #000 0 -2px 0;
    font-weight: bold;
    height: 28px;
    padding: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.15);
    .dot {
      background: red;
    }
  }
  .full-screen-btn {
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    display: none;
    z-index: 1;
    i {
      font-size: 28px;
      color: #fff;
    }
  }
}
</style>
