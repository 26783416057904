<template>
  <div class="container videos-wrap"></div>
</template>

<script>
import socket from '@/utils/socket';
import VideoSquare from './children/videoSquare.vue';
import OnlineList from './children/onlineList.vue';
import VideoBar from './children/videoBar.vue';
import { getSiteGroup, getSiteDetail } from '@/api/site';
// import { getProjectGroup } from '@/api/project';
import statusInclude from '@/utils/statusCode';
// import site from '@/store/modules/site';

const projectDefault = {
  projectID: 0,
  projectName: '全部',
  value: 0,
};

export default {
  // eslint-disable-next-line
  name: 'Videos',
  components: {
    VideoSquare,
    VideoBar,
    OnlineList,
  },
  data() {
    return {
      boxNum: 4,
      videoList: [],
      siteList: [],
      siteListCopy: [], // 搜索时用来检索数据
      projectList: [projectDefault],
      project: 0,
      searchVal: '',
      expand: {
        uavState: 0,
        osdkState: 0,
        subState: 0,
        droneUpdate: 0,
        hiveState: 0,
        weather: 0,
        windSpeed: 0,
        isRaining: 0,
        hiveUpdate: 0,
      },
      cacheSiteDetail: {},
      videoFullscreen: false,
    };
  },
  computed: {},
  watch: {
    siteList(val, old) {
      if (!old.length) {
        // this.unlockSocket();
        this.initAddVideo();
      }
    },
    boxNum(val) {
      // console.log(val, old);
      if (val === 1) {
        this.allListPlay();
      } else {
        this.cancelAllListPlay();
        this.initAddVideo();
        if (val > this.videoList.length) {
          this.addBox();
        } else if (val < this.videoList.length) {
          this.removeBox();
        }
      }
    },
  },
  created() {
    // this.getProjectList();
   
  },
  mounted() {
     this.loadData();
    this.initVideoBox();
    this.socketInstance = socket.initSocket(); // 创建socket连接
    this.fullEvent();
  },
  beforeDestroy() {
  if (this.socketInstance && this.socketInstance !== null) {
      socket.clearSocket();
      this.socketInstance = null;
    }
    this.videoList = [];
    this.removeFullEvent();
  },
  methods: {
    /**
     * 项目列表
     */
    // async getProjectList() {
    //   const { data } = await getProjectGroup();
    //   this.projectList = data;
    // },
    /**
     * 站点列表
     */
    loadData() {
      // eslint-disable-next-line consistent-return
      this.getSiteListData().then((siteData) => {
        if (siteData === '请求站点信息失败') return this.$message({ type: 'warning', message: siteData });
        this.siteList = siteData.map((x) => {
          const active = false;
          const viewValue = 'first';
          return Object.assign(x, this.expand, { active, viewValue });
        });
        this.siteListCopy = JSON.parse(JSON.stringify(this.siteList));
      });
    },
    searchFun() {
      const searchList = this.siteListCopy.filter((val) => this.searchLimit(val));
      this.siteList = searchList;
    },
    searchLimit(data) {
      if (!data) {
        return;
      }
      const { siteName = '', siteID = '' } = data;
      const text = this.searchVal;
      if (siteName.includes(text) || siteID.includes(text)) {
        // eslint-disable-next-line consistent-return
        return true;
      }
      // eslint-disable-next-line consistent-return
      return false;
    },
    /**
     * 基础数据事件
     */
    initVideoBox() {
      for (let i = 0; i < this.boxNum; i += 1) {
        this.videoList.push({});
      }
    },
    /**
     * 宫格切换事件
     */
    // 全部滚动模式 boxNum = 1
    allListPlay() {
      this.videoList = [];
      this.videoList = JSON.parse(JSON.stringify(this.siteList));
      this.siteList.forEach((x) => {
        this.listAddActive(x);
        this.squareAddVideo(x);
      });
    },
    cancelAllListPlay() {
      this.siteList.forEach((x) => {
        this.listRemoveActive(x);
        this.squareRemoveVideo(x);
      });
    },
    // 宫格模式 boxNum = 4, 6, 9
    addBox() {
      const time = this.boxNum - this.videoList.length;
      for (let i = 0; i <= time; i += 1) {
        this.videoList.push({});
      }
    },
    removeBox() {
      const num = this.videoList.length - this.boxNum;
      for (let i = 0; i < num; i += 1) {
        const delVideo = this.videoList.pop();
        if (delVideo.siteID) {
          this.listRemoveActive(delVideo);
        }
      }
    },
    videoBarAction(data) {
      this.boxNum = data.data.num || 4;
    },
    /**
     * 站点列表事件
     */
    listAddActive(item) {
      Object.assign(item, {
        active: true,
      });
      this.copyListUpdata(item, true);
    },
    listRemoveActive(item) {
      Object.assign(item, {
        active: false,
      });
      this.copyListUpdata(item, false);
    },
    async listAction(data) {
      const index = this.siteList.findIndex((x) => x.siteID === data.data.siteID);
      let siteItem = {};
      if (index > -1) {
        siteItem = this.siteList[index];
      }
      if (data && data.action === 'addVideo') {
        this.listAddActive(siteItem);
        this.squareAddVideo(data.data);
      }
      if (data && data.action === 'removeVideo') {
        this.listRemoveActive(siteItem);
        this.squareRemoveVideo(data.data);
      }
      if (data && data.action === 'updateDuration') {
        this.$refs.VideoSquare.updateTime(data.data);
      }
    },
    copyListUpdata(item, active) {
      const index = this.siteListCopy.findIndex((x) => x.siteID === item.siteID);
      if (index > -1) {
        this.siteListCopy[index].active = active;
      }
    },
    /**
     * 视频播放区事件
     */
    async squareAddVideo(item) {
      await this.setVideoInfo(item);
      this.$refs.VideoSquare.playVideo(item);
    },
    squareRemoveVideo(item) {
      const videoIndex = this.videoList.findIndex((x) => x.siteID === item.siteID);
      if (videoIndex > -1) {
        this.$set(this.videoList, videoIndex, {});
        this.$refs.VideoSquare.removePlay(item.siteID);
      }
    },
    videoSquareAction(data) {
      if (data && data.action === 'delVideo') {
        this.$refs.OnlineList.toggleActive(data.data);
      }
      if (data && data.action === 'videoFullScreen') {
        const dom = document.getElementById(data.data.videoId);
        if (!this.videoFullscreen) {
          this.fullScreen(dom);
        } else {
          this.exitFullscreen();
        }
      }
    },
    async setVideoInfo(data) {
      const urls = await this.getSiteDetailFun(data.siteID);
      // eslint-disable-next-line
      this.videoList.some((val, index) => {
        if (!val.siteID) {
          this.$set(this.videoList, index, Object.assign(data, urls));
          return true;
        }
        if (val.siteID === data.siteID) {
          this.$set(this.videoList, index, Object.assign(data, urls));
          // this.videoList[index] = Object.assign(data, urls);
          // this.videoList[index].dTime = parseFloat(Number(new Date().getTime() / 1000).toFixed(0)) + 1;
          return true;
        }
      });
      // this.videoList[index] = Object.assign(data, urls);
      // this.addCommonVideo(data);
    },
    /**
     * 事件
     */
    initAddVideo() {
      if (this.boxNum === 1) {
        return;
      }
      const len = this.boxNum > this.siteList.length ? this.siteList.length : this.boxNum;
      for (let i = 0; i < len; i += 1) {
        const item = this.siteList[i];
        if (item) {
          this.listAction({
            action: 'addVideo',
            data: item,
          });
        }
      }
    },
    async getSiteListData({ page = 1, limit = 100 } = {}) {
      const { code, data, reason } = await getSiteGroup(page, limit);
      if (statusInclude(code)) {
        const { list } = data;
        return list;
      }
      return new Error(`请求站点信息失败${reason}`);
    },
    /**
     * 获取站点详情
     */
    async getSiteDetailFun(siteID) {
      let site = {};
      if (this.cacheSiteDetail[siteID]) {
        site = this.cacheSiteDetail[siteID];
      } else {
        const { code, data } = await getSiteDetail(siteID);
        // console.log(code, data);
        if (!statusInclude(code)) {
          this.$message.error('请求数据失败');
          return { first: '', second: '', internal: '' };
        }
        site = data;
        this.cacheSiteDetail[siteID] = data;
      }
      const { UAVInfo = {}, siteFLVURL, hiveInfo = {} } = site;
      // const site = data;
      // if (site.siteMode !== SITE_HIVE_TYPE.SITEMODE.hiveAndRadioFrequencyBox) {
      //   this.$refs.controlsBar.hideHiveLabel();
      //   this.$refs.controlsBar.hideScheduleLabel();
      // }
      let UAVFLVURL = '';
      let hiveFLVURL = '';
      if (UAVInfo && UAVInfo.UAVFLVURL) {
        UAVFLVURL = UAVInfo.UAVFLVURL;
      }
      if (hiveInfo && hiveInfo.hiveFLVURL) {
        hiveFLVURL = hiveInfo.hiveFLVURL;
      }
      return {
        // liveAddr: UAVFLVURL, // UAVFLVURL,
        first: UAVFLVURL, // 'https://play5.skysys.cn/xl_uav/ch125.flv?auth_key=1652253863-0-0-63c58d7f55830f69a59a424f08b65644',
        second: siteFLVURL,
        internal: hiveFLVURL,
      };
    },
    /**
     * socket事件
     */
    unlockSocket() {
      this.siteList.forEach((site) => {
        this.socketInstance.on(`${site.siteID} state`, (data) => {
          // 开启主题监听
          const { type } = data;
          if (type === 1) {
            Object.assign(site, {
              uavState: data.isUAVOnline ? 1 : 0,
              osdkState: data.isSCOnline,
              subState: data.subState,
              droneUpdate: new Date().getTime() + 5000,
            });
            // 无人机上线，给站点添加位置
            // if (site.uavState) {
            //   site.siteAddress = '无人机位置';
            //   site.siteLocation = [data.longitudeWGS, data.latitudeWGS];
            // }
          } else {
            const a = data.windSpeed > 8 ? 401 : 200;
            const b = data.isRaining ? 121 : 313;
            Object.assign(site, {
              hiveState: 1,
              weather: a + b,
              windSpeed: data.windSpeed,
              isRaining: b,
              hiveUpdate: new Date().getTime() + 5000,
            });
          }
        });
        this.heartBeating(site);
      });
    },
    heartBeating(site) {
      this.heartBeatingTimer = setInterval(() => {
        const time = new Date().getTime();
        const { droneUpdate, hiveUpdate } = site;
        if (!droneUpdate && !hiveUpdate) {
          Object.assign(site, {
            uavState: 0,
            osdkState: 0,
            subState: 0,
            hiveState: 0,
            weather: 0,
          });
        } else if (droneUpdate && droneUpdate < time) {
          Object.assign(site, {
            uavState: 0,
            osdkState: 0,
            subState: 0,
          });
        } else if (hiveUpdate && hiveUpdate < time) {
          Object.assign(site, {
            hiveState: 0,
            weather: 0,
          });
        }
      }, 30000);
    },
    /**
     * 全屏事件
     */
    fullEvent() {
      this.allListener('fullscreenchange');
      this.allListener('webkitfullscreenchange');
      this.allListener('mozfullscreenchange');
      this.allListener('msfullscreenchange');
    },
    removeFullEvent() {
      window.removeEventListener('fullscreenchange', () => {});
      window.removeEventListener('webkitfullscreenchange', () => {});
      window.removeEventListener('mozfullscreenchange', () => {});
      window.removeEventListener('msfullscreenchange', () => {});
    },
    allListener(ev) {
      window.addEventListener(ev, () => {
        let isFull = document.fullscreenElement;
        const d = document;
        if (ev === 'webkitfullscreenchange') {
          isFull = d.webkitFullscreenElement;
        } else if (ev === 'mozfullscreenchange') {
          isFull = d.mozFullscreenElement;
        } else if (ev === 'msfullscreenchange') {
          isFull = d.msFullscreenElement;
        }
        if (isFull) {
          this.videoFullscreen = true;
        } else {
          this.videoFullscreen = false;
        }
      });
    },
    fullScreen(dom) {
      if (dom.requestFullscreen) {
        dom.requestFullscreen();
      } else if (dom.msRequestFullscreen) {
        dom.msRequestFullscreen();
      } else if (dom.mozRequestFullScreen) {
        dom.mozRequestFullScreen();
      } else if (dom.webkitRequestFullscreen) {
        dom.webkitRequestFullscreen();
      }
    },
    exitFullscreen() {
      const d = document;
      if (d.exitFullscreen) {
        d.exitFullscreen();
      } else if (d.msExitFullscreen) {
        d.msExitFullscreen();
      } else if (d.mozCancelFullScreen) {
        d.mozCancelFullScreen();
      } else if (d.webkitExitFullscreen) {
        d.webkitExitFullscreen();
      }
    },
    /**
     * 事件
     */
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: calc(100vh - 92px);
  padding: 10px 20px 20px;
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  overflow: hidden;
  &--white {
    background-color: #f0f0f0;
    height: 300px;
  }
  .container-inner {
    position: relative;
    width: 100%;
  }
  .top-bar {
    display: flex;
    align-items: center;
    .filter-item {
      margin-right: 20px;
      display: flex;
      align-items: center;
      /deep/ .el-select .el-input__inner,
      /deep/ .el-input__inner,
      button {
        height: 30px;
        line-height: 30px;
      }
      button {
        padding: 0 20px;
      }
      /deep/ .el-input__icon {
        line-height: 30px;
      }
    }
  }
  .content {
    margin-top: 20px;
    height: calc(100% - 60px);
    width: 100%;
    display: flex;
    .side-bar {
      display: flex;
      margin-right: 20px;
      background-color: #0b172f;
    }
  }
}
</style>
