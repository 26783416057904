<template>
  <div :class="['videos-box', `mode-${boxNum}`]">
    <div
      v-if="boxNum === 1"
      class="scroll-box"
    >
      <VideoItem
        v-for="(item, index) in videoList"
        :key="index"
        :index="index"
        :data="item"
        :draggable="false"
        @emitAction="videoItemEvent"
      />
    </div>
    <VideoItem
      v-for="(item, index) in videoList"
      v-else
      :key="index"
      :index="index"
      :data="item"
      :draggable="true"
      @emitAction="videoItemEvent"
    />
  </div>
</template>

<script>
import FLV from 'flv.js';
import socket from '@/utils/socket';
import VideoItem from './videoItem.vue';

let oldData = null;
let newData = null;
const flvPlayers = {};

export default {
  // eslint-disable-next-line
  name: 'VideoSquare',
  components: {
    VideoItem
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    boxNum: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      videoList: []
    };
  },
  computed: {},
  watch: {
    list: {
      handler(val) {
        // console.log('watch video list', val);
        this.videoList = val;
        // this.videoList = this.videoList.concat([]);
        // console.log('video list', this.videoList);
      },
      immediate: true,
      deep: true
    }
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
    oldData = null;
    newData = null;
  },
  methods: {
    playVideo(video) {
      const liveAddr = this.getLiveAddr(video);
      if (liveAddr) {
        this.$nextTick(() => {
          const dom = document.getElementById(`video-${video.siteID}`);
          const { siteID } = video;
          flvPlayers[siteID] = FLV.createPlayer(
            {
              type: 'flv',
              hasAudio: false,
              isLive: true,
              // cors: true, // 是否跨域
              // @ts-ignore
              // liveBufferLatencyChasing: true,
              url: liveAddr
            },
            {
              cors: false, // 是否跨域
              enableWorker: true, // 是否多线程工作
              enableStashBuffer: false, // 是否启用缓存
              stashInitialSize: 8192, // 缓存大小(kb)  默认384kb
              autoCleanupSourceBuffer: true, // 是否自动清理缓存
              reuseRedirectedURL: true // 重用301/302重定向url，用于随后的请求，如查找、重新连接等。
            }
          );
          if (flvPlayers[siteID]) {
            flvPlayers[siteID].attachMediaElement(dom);
            flvPlayers[siteID].load();
            flvPlayers[siteID].play();
          }
        });
      }
    },
    removePlay(siteID) {
      if (flvPlayers[siteID]) {
        flvPlayers[siteID].unload();
        flvPlayers[siteID].destroy();
        flvPlayers[siteID] = null;
      }
    },
    videoItemEvent(data) {
      if (data && data.action === 'toggleVideoView') {
        this.toggleVideoView(data.data);
      }
      if (data && data.action === 'videoFullScreen') {
        this.videoFullScreen(data.data.siteID);
      }
      if (data && data.action === 'dragstart') {
        this.dragstart(data.data.value);
      }
      if (data && data.action === 'dragenter') {
        this.dragenter(data.data.value, data.data.e);
      }
      if (data && data.action === 'dragend') {
        this.dragend();
      }
      if (data && data.action === 'dragover') {
        this.dragover(data.data.e);
      }
    },
    toggleVideoView(video) {
      const { siteID } = video;
      this.removePlay(siteID);
      this.playVideo(video);
    },
    getLiveAddr(video) {
      const { first, second, internal, viewValue } = video;
      switch (viewValue) {
        case 'first':
          return first;
        case 'second':
          return second;
        case 'internal':
          return internal;
        default:
          return first;
      }
    },
    videoFullScreen(videoId) {
      this.$emit('emitAction', {
        action: 'videoFullScreen',
        data: {
          videoId
        }
      });
    },
    dragstart(value) {
      oldData = value;
    },
    // 记录移动过程中信息
    dragenter(value, e) {
      newData = value;
      e.preventDefault();
    },
    // 拖拽最终操作
    dragend() {
      // 拖动空白窗口到空白窗口
      if (!oldData.siteID && !newData.siteID) {
        return;
      }
      if (oldData !== newData) {
        const oldIndex = this.videoList.indexOf(oldData);
        const newIndex = this.videoList.indexOf(newData);
        const newItems = [...this.videoList];
        // 互换位置
        newItems[newIndex] = this.videoList[oldIndex];
        newItems[oldIndex] = this.videoList[newIndex];
        this.videoList = [...newItems];
        const oldSiteID = oldData.siteID;
        const newSiteID = newData.siteID;
        // 拖动到空白的窗口
        if (!newData.siteID) {
          this.removePlay(oldSiteID);
          this.playVideo(oldData);
        } else {
          this.removePlay(oldSiteID);
          this.playVideo(oldData);
          this.removePlay(newSiteID);
          this.playVideo(newData);
        }
      }
    },
    // 拖动事件（主要是为了拖动时鼠标光标不变为禁止）
    dragover(e) {
      e.preventDefault();
    }
  }
};
</script>

<style lang="scss" scoped>
.videos-box {
  padding: 4px;
  flex: 1;
  height: 100%;
  background-color: #0b172f;
  &.mode-4,
  &.mode-2 {
    .video-box {
      width: 50%;
      height: 50%;
    }
  }
  &.mode-6 {
    .video-box {
      width: 33.33%;
      height: 33.33%;
      &:first-child {
        width: 66.6%;
        height: 66.6%;
      }
    }
  }
  &.mode-9 {
    .video-box {
      width: 33.33%;
      height: 33.33%;
    }
  }
  &.mode-1{
    overflow-y: auto;
    .scroll-box{
      height: 100%;
      .video-box{
        width: 50%;
        height: 50%;
      }
    }
  }
  .video-box {
    padding: 4px;
    float: left;
    transition: all 0.3s;
  }
}
</style>
