<template>
  <div class="video-bar-c">
    <div
      v-for="(item, index) in barList"
      :key="index"
      class="bar-item"
      @click="clickFun(item)"
    >
      <div :class="['bar-icon', `icon${item.num}`, {active: item.active}]" />
    </div>
  </div>
</template>

<script>
import icon1 from '@/assets/images/videos/four_box.png';
import icon2 from '@/assets/images/videos/six_box.png';
import icon3 from '@/assets/images/videos/nine_box.png';
import icon4 from '@/assets/images/videos/all_box.png';

export default {
  name: 'OnlineList',
  components: {
  },
  props: {
    videoList: {
      type: Array,
      default: () => []
    },
    boxNum: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      activeBoxNum: 4,
      barList: [
        {
          active: true,
          iconUrl: icon1,
          num: 4
        },
        {
          active: false,
          iconUrl: icon2,
          num: 6
        },
        {
          active: false,
          iconUrl: icon3,
          num: 9
        },
        {
          active: false,
          iconUrl: icon4,
          num: 1
        }
      ]
    };
  },
  computed: {
  },

  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    clickFun(data) {
      this.toggleBox(data);
    },
    toggleBox(data) {
      if (this.activeBoxNum === data.num) {
        return;
      }
      this.activeBoxNum = data.num || 4;
      this.barList.forEach((val) => {
        if (val.num === this.activeBoxNum) {
          Object.assign(val, {
            active: true
          });
        } else {
          Object.assign(val, {
            active: false
          });
        }
      });
      this.$emit('emitAction', {
        action: 'box',
        data: {
          num: this.activeBoxNum
        }
      });
    }
  }
};
</script>

<style lang="scss">
.video-bar-c {
  width: 60px;
  height: 100%;
  border-right: solid 1px rgba(216, 216, 216, 0.1);
  .bar-item {
    padding: 20px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-bottom: solid 1px rgba(216, 216, 216, 0.1);
    &:last-child {
      border-bottom: solid 1px transparent;
    }
    .bar-icon{
      width: 26px;
      height: 26px;
      margin: auto;
      background: url('~@/assets/images/videos/four_box.png') no-repeat;
      &.icon6{
        background: url('~@/assets/images/videos/six_box.png') no-repeat;
      }
      &.icon9{
        background: url('~@/assets/images/videos/nine_box.png') no-repeat;
      }
      &.icon1{
        background: url('~@/assets/images/videos/all_box.png') no-repeat;
      }
      &.active{
        background-position: -30px 0;
      }
    }
  }
}
</style>
