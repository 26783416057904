<template>
  <div class="list-main">
    <div class="page-header">
      场站列表
    </div>
    <div class="device-list">
      <div
        v-for="(item) in siteList"
        :key="item.did"
        class="device-item"
        :class="{active: item.active}"
        @click="toggleActive(item)"
      >
        <div class="name eps-1">
          <!-- <span
              v-if="item.liveAddr"
              class="live-status"
            >
              <span />
            </span> -->
          <span
            :name="item.siteName"
            class="search-color"
          >{{ item.siteName }}</span>
        </div>
        <div class="id eps-1">
          <span>ID: {{ item.siteID }}</span>
        </div>
        <div class="time eps-1">
          <span>{{ item.createTime }}</span>
        </div>
        <div class="device-pic">
          <img
            v-if="item.UAVInfo || item.hiveinfo"
            :src="item | siteImg"
            alt="img"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uavM210V2 from '@/assets/images/device/uav_M210V2.png';
import uavM300 from '@/assets/images/device/uav_M300.png';
import uavMAVIC2 from '@/assets/images/device/uav_MAVIC2.png';
import UHMK4Pro from '@/assets/images/device/UH_MK4_Pro.png';
import UHMK5 from '@/assets/images/device/UH_MK5.png';
import img2 from '@/assets/images/device/img2.png';

export default {
  name: 'OnlineList',
  components: {
  },
  filters: {
    siteImg(site) {
      if (site.siteMode === 4 && site.UAVInfo) {
        switch (site.UAVInfo.model) {
          case 'M210V2':
            return uavM210V2;
          case 'M300':
            return uavM300;
          case 'MAVIC2':
            return uavMAVIC2;
          default:
            return img2;
        }
      } else if (site.siteMode === 1 && site.hiveinfo) {
        switch (site.hiveinfo.hiveModel) {
          case 'UH_MK4_Pro':
            return UHMK4Pro;
          case 'UH_MK5':
            return UHMK5;
          default:
            return img2;
        }
      } else {
        return '';
      }
    }
  },
  props: {
    siteList: {
      type: Array,
      default: () => []
    },
    videoList: {
      type: Array,
      default: () => []
    },
    boxNum: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      cacheSiteId: {}
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    toggleActive(item) {
      if (this.isVideoFull(item.active)) {
        return;
      }
      // this.searchText = '';
      // this.searchFun();
      if (item.active) {
        this.removeActiveList(item);
      } else {
        this.activeList(item);
      }
      // this.deviceList = this.deviceList.concat([]);
    },
    activeList(item) {
      this.$emit('emitAction', {
        action: 'addVideo',
        data: item
      });
    },
    removeActiveList(item) {
      if (this.boxNum === 1) {
        return;
      }
      this.$emit('emitAction', {
        action: 'removeVideo',
        data: {
          siteID: item.siteID
        }
      });
    },
    isVideoFull(active) {
      const activeList = this.siteList.filter((val) => val.active);
      if (activeList.length >= this.boxNum && !active) {
        this.$message.warning('视频列表已满，请先取消已播放的视频');
        return true;
      }
      return false;
    }
    // removeAllVideo() {
    //   this.deviceList.forEach((val) => {
    //     val.active = false;
    //     this.cacheDid[val.did] = false;
    //   });
    //   this.deviceList = this.deviceList.concat([]);
    //   this.personList.forEach((val) => {
    //     val.active = false;
    //     this.cacheDid[val.did] = false;
    //   });
    //   this.personList = this.personList.concat([]);
    // }
  }
};
</script>

<style lang="scss">
.list-main {
  width: 230px;
  .page-header{
    position: relative;
    font-size: 18px;
    color: #fff;
    padding: 18px 40px;
    &::before{
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #378CFF;
      position: absolute;
      top: 50%;
      margin-top: -5px;
      left: 20px;
    }
  }
}
.no-list-data {
  margin-top: 98px;
  width: 100%;
  height: auto;
  text-align: center;
  img {
    width: 128px;
    height: 128px;
    margin-bottom: 23px;
  }
  p {
    height: 22px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.45);
    line-height: 22px;
  }
}
.live-status {
  width: 14px;
  height: 14px;
  background: #1CD167;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-right: 6px;
  vertical-align: middle;
  margin-bottom: 4px;
  span {
    display: inline-block;
    width: 0;
    height: 0;
    border: solid 4px transparent;
    border-left-color: #fff;
    position: absolute;
    top: 3px;
    left: 6px;
  }
}
.device-list {
  height: calc(100% - 56px);
  overflow-y: auto;
  &::-webkit-scrollbar{
    width :3px;
  }
  &::-webkit-scrollbar-thumb{
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
  }
  &::-webkit-scrollbar-track{
    background-color: #18273d;
  }
  .device-item {
    cursor: pointer;
    width: 100%;
    // height: 68px;
    border-top: solid 1px rgba(255, 255, 255, 0.15);
    padding: 12px 13px;
    position: relative;
    &.active {
      border-left: solid 2px #337BFA !important;
      background: linear-gradient(280deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.06) 100%);
    }
    &:hover {
      background: linear-gradient(280deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.06) 100%);
      .btn-c {
        width: 80px;
      }
    }
    .name {
      width: 144px;
      // height: 20px;
      font-size: 14px;
      font-weight: bold;
      color: rgba(255, 255, 255, 0.85);
      line-height: 20px;
    }
    .time {
      width: 150px;
      height: 14px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.45);
      line-height: 14px;
      margin: 10px 0;
    }
    .id {
      height: 17px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.45);
      line-height: 17px;
    }
    .device-pic {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 68px;
      height: 68px;
      overflow: hidden;
      display: flex;
      align-items: center;
      img {
        width: 100%;
      }
    }
  }
}
</style>
